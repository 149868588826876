import store from '@/store/index'
import { AVAILABLE_COUNTRIES } from "@/enums";

const getChangesFromPlace = function(place) {
    let address1 = "";
    let postalCode = "";
    let city = "";
    let country = "";
    let state = null;

    //console.log('getChangesFromPlace', place)

    if (place.geometry) {
        
        //console.log(place.address_components);

        for (let i = place.address_components.length-1; i >= 0; i--) {
            let component = place.address_components[i];
            const componentType = component.types[0];

            switch (componentType) {
                case "street_number": {
                    if (country && country.id == AVAILABLE_COUNTRIES.BELGIUM) {
                        address1 = address1 + ' ' + component.long_name;
                    }
                    else {
                        address1 = component.long_name + ' ' + address1;
                    }
                    break;
                }
                case "route": {
                    address1 += component.short_name;
                    break;
                }
                case "postal_code": {
                    postalCode = `${component.long_name}${postalCode}`;
                    break;
                }
                case "locality":
                case "postal_town":
                case "sublocality_level_1": {
                    if (!city) {
                        city = component.long_name;
                    }
                    break;
                }
                case "country": {
                    country = store.state.countries.find(c => c.code == component.short_name);
                    
                    if (!country) {
                        // Gestion des exceptions. Ces régions de la France sont spécifiés comme Pays.
                        switch (component.long_name) {
                            case 'Mayotte':
                            case 'French Guiana':
                            case 'Réunion':
                            case 'Martinique':
                            case 'Guadeloupe':
                                country = store.state.countries.find(c => c.code == 'FR');
                                state = country.states.find(s => s.code == component.short_name);
                                break;
                            default: 
                                break;
                        }
                    }
                    break;
                }
                case "administrative_area_level_1":
                case "administrative_area_level_2":
                case "administrative_area_level_3": {

                    // Si l'état est déjà déterminé, on skip.
                    if (!state && country) {
                        // On recherche par le code de l'état d'abord
                        state = country.states.find(s => s.code == component.short_name);
                        // Si pas de correspondance, on recherche par le nom anglais
                        if (!state) {
                            state = country.states.find(s => s.nameEnglish == component.long_name);
                        }
                    }
                    break;
                }
                default:
                    break;
            }
        }

        let changes = {
          address1: address1,
          postalCode: postalCode,
          city: city,
          countryId: country ? country.id : null,
          stateId: state ? state.id : null
        }
        
        //console.log(changes);
        
        return changes;
    }
}

export {
    getChangesFromPlace
}