<template>
    <div>
        <label
            v-if="title !== ''"
            style="display:block;margin-bottom:10px;font-weight:bold;"

            :disabled="disabled"
        >
            {{ title }}
        </label>
        <v-row>
            <v-col cols="12" style="position:relative;">
                <v-text-field
                    v-model="changes.address1"
                    @change="saveAddress1()"

                    :disabled="disabled"
                    :class="{ 'errored' : errorAddress1 }"
                    :label="$t('addressField.address1')"
                    :id="id"

                    hide-details
                    maxlength="250"
                    dense
                    :style="{ 'width': showMarker ? 'calc(100% - 30px)' : '100%' }"
                />
                <v-icon v-if="showMarker" :disabled="mapDisabled" style="position:absolute;top:10px;right:10px;cursor:pointer;" @click="openMap()">mdi-map-marker</v-icon>
            </v-col>
            <v-col cols="12">
                <v-text-field
                v-model="changes.address2"
                @change="saveAddress()"

                :disabled="disabled"
                :label="$t('addressField.address2')"

                hide-details
                maxlength="250"
                dense               
            />
            </v-col>
        
            <v-col cols="12">
                <v-text-field
                v-model="changes.city"
                @change="saveAddress()"

                :disabled="disabled"
                :class="{ 'errored' : errorCity }"
                :label="$t('addressField.city')"

                hide-details
                maxlength="100"
                dense
            />
            </v-col>
            <v-col cols="12" v-if="showAddressType">
                <v-select
                    v-model="changes.addressType"
                    @change="saveAddress();"

                    :disabled="disabled"
                    :items="[ {id: 0, name: $t('addressField.types.physical')}, {id: 1, name: $t('addressField.types.postal')} ]"
                    item-text="name"
                    item-value="id"
                    :label="$t('addressField.addressType')"
                    hide-details

                    dense
                />
            </v-col>

            <v-col cols="6">
                <v-select
                    v-model="changes.countryId"
                    @change="countryChanged(address.countryId)"
                    
                    :disabled="disabled"
                    :items="countries"
                    :class="{ 'errored' : errorCountry }"
                    :label="$t('addressField.country')"

                    item-text="name"
                    item-value="id"
                    hide-details

                    dense
                />
            </v-col>
            <v-col cols="6">
                <v-select
                    v-if="changes.countryId"
                    v-model="changes.stateId"
                    @change="saveAddress()"
                    
                    :disabled="disabled"
                    :items="currentCountryStates"
                    :class="{ 'errored' : errorState }"
                    
                    :label="address.countryId != 39 ? $t('addressField.state') : $t('addressField.province')"
                    
                    item-text="name"
                    item-value="id"
                    hide-details

                    dense
                />
            </v-col>
            <v-col cols="6">
                <v-text-field
                    v-model="changes.poBox"
                    @change="saveAddress()"
                    
                    :disabled="disabled"
                    :class="{ 'errored': errorPoBox }"
                    :label="$t('addressField.poBox')"

                    maxlength="45"
                    dense
                    hide-details
                />
            </v-col>
            <v-col cols="6">
                <v-text-field
                    v-model="changes.postalCode"
                    @change="saveAddress()"

                    :disabled="disabled"
                    :label="$t('addressField.postalCode')"

                    maxlength="45"
                    dense
                    hide-details
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import { capitalizeFirstLetter } from '@/utils/validation';
import { getChangesFromPlace } from './inject';

/*global google*/

export default {
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        mapDisabled: {
            type: Boolean,
            default: false,
        },
        address: {
            type: Object,
            required: true,
        },
        title: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            default: 'autocomplete'
        },
        country: {
            type: [String, Array],
            default: '',
        },
        errorAddress1: {
            type: Boolean,
            default: false,
        },
        errorCity: {
            type: Boolean,
            default: false,
        },
        errorCountry: {
            type: Boolean,
            default: false,
        },
        errorState: {
            type: Boolean,
            default: false,
        },
        errorPoBox: {
            type: Boolean,
            default: false,
        },
        saveAllProperties: {
            type: Boolean,
            default: false,
        },
        showAddressType: {
            type: Boolean,
            default: false,
        },
        showMarker: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            changes: {
                address1: this.address.address1,
                address2: this.address.address2,
                city: this.address.city,
                countryId: this.address.countryId,
                stateId: this.address.stateId,
                poBox: this.address.poBox,
                postalCode: this.address.postalCode,
                addressType: this.address.addressType,
            },
            autocomplete: null,
            keyUpdater: 0,
            address1Timeout: null
        }
    },
    methods: {
        ...mapMutations([
            'openToast',
        ]),
        bindMap() {
            const options = {};
            let inputAutocomplete = document.getElementById(this.id);
            if (this.country[0]){
                options.componentRestrictions = {
                    country: this.country
                };
            }
            
            this.autocomplete = new google.maps.places.Autocomplete(inputAutocomplete, options);
            this.autocomplete.addListener('place_changed', () => (this.onPlaceChanged(inputAutocomplete)));
            
            setTimeout(() => { inputAutocomplete.placeholder = "" }, 500);
            
            if(this.autocomplete) {
                this.geolocate()
            }

        },
         geolocate() {
            const scope = this
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function(position) {
                    var geolocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    var circle = new google.maps.Circle({
                        center: geolocation,
                        radius: position.coords.accuracy
                    });

                    //console.log("GEOLOCATE", geolocation)

                    scope.autocomplete.setBounds(circle.getBounds());
                });
            }
        },
        updateAddress(newVal) {
            this.changes.address1 = newVal.address1;
            this.changes.address2 = newVal.address2;
            this.changes.city = newVal.city;
            this.changes.countryId = newVal.countryId;
            this.changes.stateId = newVal.stateId;
            this.changes.poBox = newVal.poBox;
            this.changes.postalCode = newVal.postalCode;
            this.changes.addressType = newVal.addressType;
        },
        updateAddressTypeForDealer(newVal) {
            console.log(newVal)
            this.changes.addressType = newVal;
        },
        emptyAddress() {
            return {
                address1: '',
                address2: '',
                city: '',
                poBox: '',
                postalCode: '',
                addressType: '',
            }
        },
        saveAddress1() {
            // Au changement de l'adresse 1, on donne 250ms à onPlaceChanged d'être callé avant de sauvegarder
            // afin d'éviter 2 sauvegardes une à la suite de l'autre
            if (this.address1Timeout) {
                clearTimeout(this.address1Timeout);
            }
            this.address1Timeout = setTimeout(() => { this.saveAddress() }, 250);
        },
        saveAddress() {
            if (this.address1Timeout) {
                clearTimeout(this.address1Timeout);
                this.address1Timeout = null;
            }

            let actualChanges = {};

            if(this.saveAllProperties || this.changes.address1 != this.address.address1)
                actualChanges.address1 = capitalizeFirstLetter(this.changes.address1);

            if(this.saveAllProperties || this.changes.address2 != this.address.address2)
                actualChanges.address2 = capitalizeFirstLetter(this.changes.address2);

            if(this.saveAllProperties || this.changes.city != this.address.city)
                actualChanges.city = capitalizeFirstLetter(this.changes.city);

            if(this.saveAllProperties || this.changes.poBox != this.address.poBox)
                actualChanges.poBox = capitalizeFirstLetter(this.changes.poBox);

            if(this.saveAllProperties || this.changes.postalCode != this.address.postalCode)
                actualChanges.postalCode = capitalizeFirstLetter(this.changes.postalCode);

            if(this.saveAllProperties || this.changes.countryId != this.address.countryId)
                actualChanges.countryId = this.changes.countryId;

            if(this.saveAllProperties || this.changes.stateId != this.address.stateId)
                actualChanges.stateId = this.changes.stateId;

            if(this.saveAllProperties || this.changes.addressType != this.address.addressType)
                actualChanges.addressType = this.changes.addressType;

            this.$emit('saveAddress', actualChanges);
            this.$forceUpdate();
        },
        // Special case
        countryChanged(newCountryId) {
            this.changes.country = this.$store.state.countries.find(c => c.id == newCountryId);
            this.changes.stateId = null;
            this.saveAddress();
        },
        // Whenever google autocomplete is used
        onPlaceChanged(input) {

          if (this.address1Timeout) {
            clearTimeout(this.address1Timeout);
            this.address1Timeout = null;
          }

          let currentChanges = getChangesFromPlace(this.autocomplete.getPlace(), this.address);

            // Si il y a trigger sans qu'il y ait de 'place'
            if(currentChanges == null)
                return;

            if(currentChanges.countryId) {

                //Si les changements proposés par l'autocomplete de google sont valides
                input.value = this.changes.address1;
                this.changes = {...this.changes, ...currentChanges}

                // On donne un peu de temps puisque le change de Address1 changed + onPlaceChanged sont très près l'un de l'autre
                setTimeout(() => { this.saveAddress() }, 50);
            } else {

                // On gère les cas où le pays/l'état n'est pas valide
                input.value = '';
                this.changes = {...this.changes, ...this.emptyAddress()};

                this.openToast({ message: this.$t('validation.google_valid_address'), duration: 5000, color: "red" });
            }
        },
        openMap() {
          this.$emit('onShowMap')
        }
    },
    computed: {
        ...mapState({
            countries: (state) => state.countries,
        }),
        currentCountryStates: function() {
            let currentCountry = this.countries.find(c => (c.id === this.changes.countryId));

            return currentCountry ? currentCountry.states : [];
        }
    },
    mounted() {
        this.bindMap();
    },
}
</script>

<style scoped>
label {
  color: rgba(0, 0, 0, 0.6);
}
</style>
